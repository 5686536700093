import { navigate } from "gatsby";
import { isExcludedUrl } from "../helpers/seo";

export type Redirect = {
  source: string;
  destination: string;
  statusCode: number;
};

// We'll store the redirects once they're loaded
let redirectsCache: Redirect[] | null = null;

/**
 * Load redirects from the JSON file
 */
export const loadRedirects = async (): Promise<Redirect[]> => {
  // Return from cache if available
  if (redirectsCache) {
    return redirectsCache;
  }

  try {
    // In SSR/build context, there's no window
    if (typeof window === "undefined") {
      return [];
    }

    // Fetch the redirects.json file
    const response = await fetch("/redirects.json");
    if (!response.ok) {
      console.error(
        `Failed to load redirects.json: ${response.status} ${response.statusText}`,
      );
      return [];
    }

    // Parse and cache the redirects
    const redirects = await response.json();

    if (process.env.NODE_ENV === "development") {
      console.log("Loaded redirects:", redirects);
    }

    redirectsCache = redirects;
    return redirects;
  } catch (e) {
    console.error("Error loading redirects:", e);
    return [];
  }
};

/**
 * Normalize a path for consistent matching
 */
const normalizePath = (path: string): string => {
  // Handle empty path
  if (!path) return "/";

  // Remove trailing slashes except for root path
  if (path === "/") return path;

  // Remove query parameters and hash
  let cleanPath = path.split("?")[0].split("#")[0];

  // Remove trailing slash
  cleanPath = cleanPath.replace(/\/$/, "");

  // Ensure path starts with /
  if (!cleanPath.startsWith("/")) {
    cleanPath = `/${cleanPath}`;
  }

  return cleanPath;
};

/**
 * Client-side redirect utility for handling redirects
 * This uses the redirects.json file generated at build time
 *
 * IMPORTANT: This function does NOT check for excluded URLs.
 * Always check isExcludedUrl() before calling this function.
 */
export const getRedirectDestination = async (
  path: string,
): Promise<{ destination: string; statusCode: number } | null> => {
  try {
    // Clean up the path for matching
    const normalizedPath = normalizePath(path);

    // Load redirects from the JSON file
    const redirects = await loadRedirects();

    // Find matching redirect
    const redirect = redirects.find(
      (r) => normalizePath(r.source) === normalizedPath,
    );

    if (process.env.NODE_ENV === "development") {
      console.debug(
        `Redirect lookup for ${normalizedPath}:`,
        redirect
          ? `Found -> ${redirect.destination} (${redirect.statusCode})`
          : "Not found",
      );
    }

    // If we found a redirect, return the destination and status code
    if (redirect) {
      return {
        destination: redirect.destination,
        statusCode: redirect.statusCode || 301,
      };
    }

    return null;
  } catch (e) {
    console.error("Error finding redirect:", e);
    return null;
  }
};

/**
 * Handles client-side redirects
 * @param path The current path
 * @returns A promise that resolves when the redirect is complete or not needed
 */
export const handleRedirect = async (path: string): Promise<void> => {
  try {
    // IMPORTANT: First check if the URL is in the excluded list
    // This must happen before any redirect checks
    if (isExcludedUrl(path)) {
      if (process.env.NODE_ENV === "development") {
        console.log(
          `[410] Detected excluded URL: ${path} - returning 410 Gone`,
        );
      }

      // In a client-side context, we can't directly send HTTP status codes
      // But we don't need to redirect to the 410 page anymore
      // The 410 content will be rendered directly on this URL with the correct status code

      // Make a fetch to the current URL which will be handled by middleware with 410
      // This helps with analytics and monitoring that will see the 410 status
      try {
        await fetch(path, { method: "HEAD" }).catch(() => {
          // Ignore errors - this is just to trigger the middleware
        });
      } catch (e) {
        // Ignore fetch errors
      }

      // No need to navigate away - we're already on the correct URL
      // The 410 content will be rendered directly here
      return;
    }

    // Only check for regular redirects if the URL is not excluded
    const redirectInfo = await getRedirectDestination(path);
    if (redirectInfo) {
      if (process.env.NODE_ENV === "development") {
        console.log(
          `Redirecting from ${path} to ${redirectInfo.destination} with status ${redirectInfo.statusCode}`,
        );
      }

      // For client-side navigation, we can only use JS navigation
      // In production, most redirects are handled by Vercel middleware with proper status codes
      // This is a fallback for client-side navigation
      navigate(redirectInfo.destination, { replace: true });
    }
  } catch (error) {
    console.error("Error during redirect:", error);
  }
};
