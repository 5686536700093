import excludedUrlsConfig from "../config/excluded-urls.json";
import noindexUrlsConfig from "../config/noindex-urls.json";

const excludedUrls = excludedUrlsConfig.excludedUrls;
const noindexUrls = noindexUrlsConfig.noindexUrls;

// Helper function to check if a URL is in the excluded list
export const isExcludedUrl = (pathname: string): boolean => {
  // Normalize the pathname
  const normalizedPath = pathname.replace(/^\/+|\/+$/g, "");

  // Check if the URL is in the excluded list
  return excludedUrls.some((url: string) => {
    // Normalize the excluded URL as well
    const normalizedExcludedUrl = url.replace(/^\/+|\/+$/g, "");
    return normalizedPath === normalizedExcludedUrl;
  });
};

// Helper function to check if a URL should be noindexed
export const shouldNoindex = (pathname: string): boolean => {
  // Normalize the pathname by removing trailing slash and ensuring it starts with /
  const normalizedPath = pathname.replace(/\/$/, "").replace(/^([^/])/, "/$1");

  // Check if the URL is in the noindex list
  const isInNoindexList = noindexUrls.some((url: string) => {
    // Normalize the noindex URL as well
    const normalizedUrl = url.replace(/\/$/, "").replace(/^([^/])/, "/$1");
    return normalizedPath === normalizedUrl;
  });

  // Check if the URL is in the excluded list
  const isInExcludedList = isExcludedUrl(pathname);

  // Return true if the URL is in either list
  return isInNoindexList || isInExcludedList;
};
